body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    width: 60%;
    margin: 10px auto;
}

.errorMsg {
    max-width: 350px;
    color: #f21e08;
    padding: 2px 0;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 300;
}

.hasError {
    border: 1px solid red;
}

.form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-top: 16px;
}

.form-control .box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px;
    border-color: gray;
    border-width: 1px;
    border-radius: 3px;
    border-style: solid;
    min-height: 120px;
}

.form-control .box .box-in-box {
    display: flex;
    flex-direction: column;
    padding: 6px;
    border-color: gray;
    border-width: 1px;
    border-radius: 3px;
    border-style: solid;
    min-height: 120px;
}

.form-control label {
    width: 40%;
    padding-right: 20px;
    margin-bottom: 5px;
}

.form-control .radio-group {
    display: flex;
    margin-top: 16px;
    align-items: center;
}

.form-control .radio-group label {
    font-size: 16px;
    margin-bottom: 0;
    width: 60%;
}

.form-control .radio-group input[type="radio"] {
    margin-right: 20px;
}

.form-control input[type="text"],
.form-control input[type="password"] {
    width: 40%;
    padding: 5px;
}

button {
    max-width: 150px;
    padding: 5px 10px;
}

@media screen and (max-width: 600px) {
    body {
        width: 90%;
    }

    .errorMsg {
        max-width: 100%;
    }

    .form-control {
        flex-direction: column;
    }

    .form-control input[type="text"],
    .form-control input[type="password"] {
        width: inherit;
    }
}
